import store from './../store'
import { getUser } from '@/utils/auth'

const nonRestrictedRoutes = ['login', 'orders-management', 'callback', 'registration', 'shop-setup']
export default function (to, from, next) {
  const restrictedRoutes = store.getters['restrictedRoutes'] && store.getters['restrictedRoutes'].length ? store.getters['restrictedRoutes'] : JSON.parse(localStorage.getItem('restrictedItems'))
  const isEssential = store.getters['client/client'] ? store.getters['client/client']?.isEssential : Boolean(localStorage.getItem('isEssential'))
  if (store && store.getters && store.getters['user'] && restrictedRoutes && restrictedRoutes.length) {
    if (isEssential
      && !store.getters['user']?.is_super
      && to.path
      && restrictedRoutes.some(restrictedRoute => to.path.includes(restrictedRoute.url))
    ) {
      if (from.path.includes('404')) { // go back from not found page
        return next(store.getters.lastRoute)
      }
      return next('/404')
    }
  } else if (nonRestrictedRoutes.every(item => !to.path.includes(item))) {
    return next('/')
  }
  store.dispatch('setLastRoute', from.fullPath)
  let isAuth = to.matched.some((re) => re.meta.authRequired)
  if (to.meta.isRerenderComponent) {
    store.dispatch('reRender')
  }
  const user = getUser()
  if (isAuth && !user) {
    next('/login')
  } else if (to.path === '/login' && user) {
    next('/')
  } else if (to.path === '/logout' && user) {
    store.dispatch('logout').then(() => {
      next('/login')
    })
  } else {
    next()
  }
}
