export default {
  namespaced: true,
  state: {
    client: null,
  },
  mutations: {
    SET_CLIENT: (state, client) => {
      state.client = client
    },
  },
  getters: {
    client: (state) => state.client,
  },
}
