export default [
  {
    path: 'product(/page/)?:page(\\d+)?',
    component: () => import('@/components/Views/Catalog/Product/Grid'),
    name: 'catalog_product',
    meta: {
      title: 'Manage Products',
    },
  },
  {
    path: 'product/new',
    component: () => import('@/components/Views/Catalog/Product/Edit'),
    name: 'catalog_product_new',
    meta: {
      title: 'Add New Product',
      activePath: '/catalog/product/',
    },
  },
  {
    path: 'product/edit/:id',
    component: () => import('@/components/Views/Catalog/Product/Edit'),
    name: 'catalog_product_edit',
    meta: {
      title: 'Edit Product',
      activePath: '/catalog/product/',
    },
  },
  {
    path: 'product/clone/:id',
    component: () => import('@/components/Views/Catalog/Product/Edit'),
    name: 'catalog_product_clone',
    meta: {
      title: 'Clone Product',
      activePath: '/catalog/product/',
      clone: true,
    },
  },
  /* ATTRIBUTE GROUP */
  {
    path: 'product/attribute-group',
    component: () => import('@/components/Views/Catalog/Product/AttributeGroup/Main'),
    name: 'catalog_product_attributeGroup',
    meta: {
      title: 'Manage Attribute Groups',
    },
  },
  /* ATTRIBUTE SET */
  {
    path: 'product/attribute-set',
    component: () => import('@/components/Views/Catalog/Product/AttributeSet/Main'),
    name: 'catalog_product_attributeSet',
    meta: {
      title: 'Manage Attribute Sets',
    },
  },
  /* BRAND PAGE */
  {
    path: 'brand-page',
    component: () => import('@/components/Views/Catalog/BrandPage/Main'),
    name: 'catalog_brand-page_main',
    meta: {
      title: 'Manage Brand Pages',
    },
  },
  {
    path: 'brand-page/new',
    component: () => import('@/components/Views/Catalog/BrandPage/Form'),
    name: 'catalog_brand-page_new',
    meta: {
      title: 'New Brand Page',
    },
  },
  {
    path: 'brand-page/edit/:id',
    component: () => import('@/components/Views/Catalog/BrandPage/Form'),
    name: 'catalog_brand-page_edit',
    meta: {
      title: 'Edit Brand Page',
    },
  },
  {
    path: 'ingredient-page',
    component: () => import('@/components/Views/Catalog/IngredientPage/Main'),
    name: 'catalog_ingredient_page_main',
    meta: {
      title: 'Manage Ingredient Pages',
    },
  },
  {
    path: 'ingredient-page/new',
    component: () => import('@/components/Views/Catalog/IngredientPage/Form'),
    name: 'catalog_ingredient_page_new',
    meta: {
      title: 'New Ingredient Page',
    },
  },
  {
    path: 'ingredient-page/edit/:id',
    component: () => import('@/components/Views/Catalog/IngredientPage/Form'),
    name: 'catalog_ingredient_page_edit',
    meta: {
      title: 'Edit Ingredient Page',
    },
  },
  /* IMPORT */
  {
    path: 'product/import/:id?',
    component: () => import('@/components/Views/Catalog/Product/Import/Main'),
    name: 'catalog_product_import',
    meta: {
      title: 'Import Product',
    },
  },

  /* INVENTORY */
  {
    path: 'inventory/adjustment',
    component: () => import('@/components/Views/Catalog/Inventory/Adjustment'),
    name: 'catalog_inventory_adjustment',
    meta: {
      title: 'Inventory Adjustment',
    },
  },
  {
    path: 'inventory/old-log',
    component: () => import('@/components/Views/Catalog/Inventory/OldLog/Main'),
    name: 'catalog_inventory_old_log',
    meta: {
      title: 'Inventory Log',
    },
  },
  {
    path: 'inventory/log',
    component: () => import('@/components/Views/Catalog/Inventory/Log/Main'),
    name: 'catalog_inventory_log',
    meta: {
      title: 'Inventory Log',
    },
  },
  /* CATEGORY */
  {
    path: 'categories',
    component: () => import('@/components/Views/Catalog/Category/CategoryManager'),
    name: 'catalog_category',
    meta: {
      title: 'Manage Categories',
    },
  },
  {
    path: 'categories/list/:id?',
    component: () => import('@/components/Views/Catalog/Category/CategoryList'),
    name: 'catalog_category_list',
    meta: {
      title: 'Manage Categories',
    },
  },
  {
    path: 'categories/:id',
    component: () => import('@/components/Views/Catalog/Category/CategoryDetails'),
    name: 'catalog_category_details',
    meta: {
      title: 'Category',
      activePath: '/catalog/categories/list/',
    },
  },
  /* PURCHASING */
  {
    path: 'purchasing/vendors',
    component: () => import('@/components/Views/Catalog/Purchasing/Vendors/Main'),
    name: 'vendor_management',
    meta: {
      title: 'All Vendors',
    },
  },
  {
    path: 'purchasing/vendor/new',
    component: () => import('@/components/Views/Catalog/Purchasing/Vendors/Edit'),
    name: 'purchasing_vendor_new',
    meta: {
      title: 'New Vendor',
    },
  },
  {
    path: 'purchasing/vendor/edit/:id',
    component: () => import('@/components/Views/Catalog/Purchasing/Vendors/Edit'),
    name: 'purchasing_vendor_edit',
    meta: {
      title: 'Edit Vendor',
    },
  },
  {
    path: 'purchasing/purchase-orders',
    component: () => import('@/components/Views/Catalog/Purchasing/PurchaseOrders/Main'),
    name: 'purchase_order_management',
    meta: {
      title: 'All Purchase Orders',
    },
  },
  {
    path: 'purchasing/purchase-orders/new',
    component: () => import('@/components/Views/Catalog/Purchasing/PurchaseOrders/Edit'),
    name: 'purchase_order_new',
    meta: {
      title: 'New Purchase Order',
    },
  },
  {
    path: 'purchasing/purchase-orders/edit/:id',
    component: () => import('@/components/Views/Catalog/Purchasing/PurchaseOrders/Edit'),
    name: 'purchase_order_edit',
    meta: {
      title: 'Edit Purchase Order',
    },
  },
  {
    path: 'purchasing/receipts',
    component: () => import('@/components/Views/Catalog/Purchasing/Receipts/Main'),
    name: 'catalog_purchasing_receipts',
    meta: {
      title: 'All Receipts',
    },
  },
  {
    path: 'purchasing/receipt/edit/:id',
    component: () => import('@/components/Views/Catalog/Purchasing/Receipts/Edit'),
    name: 'purchasing_receipt_edit',
    meta: {
      title: 'Edit Receipt',
    },
  },
  /* PRODUCT REVIEW */
  {
    path: 'product-review',
    component: () => import('@/components/Views/Catalog/Product/Review/Main'),
    name: 'customer_product_review_main',
    meta: {
      title: 'Product Reviews',
    },
  },
  {
    path: 'product-review/:id',
    component: () => import('@/components/Views/Catalog/Product/Review/Edit'),
    name: 'customer_product_review_edit',
    meta: {
      title: 'Product Review Detail',
    },
  },
]
