import { render, staticRenderFns } from "./ModalWidget.vue?vue&type=template&id=b4043e64&scoped=true"
import script from "./ModalWidget.vue?vue&type=script&lang=js"
export * from "./ModalWidget.vue?vue&type=script&lang=js"
import style0 from "./ModalWidget.vue?vue&type=style&index=0&id=b4043e64&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b4043e64",
  null
  
)

export default component.exports