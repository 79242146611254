<template>
  <div>
    <div class="py-1 px-3 bg-white rounded d-flex" :class="{ 'cursor-pointer': isAccessMultiClient }">
      <span
        class="font-weight-bold text-body"
        @click="handleShowClientDialog"
        title="Click to switch client">
        {{ currentClient.name || clientCode }}
      </span>
    </div>
    <select-client-modal :visible.sync="showSelectClientDialog" @selected="handleSelectClient"></select-client-modal>
    <!-- <el-dialog :visible.sync="isShowVerificationDialog" append-to-body width="30%" center>
      <div>
        <div class="text-secondary pb-3">
          Please enter the 2-factor authentication code from the email to switch client.
        </div>
        <code-input ref="code_input" @change="handleChangeCode" :code-length="codeLength" />
      </div>
      <div slot="footer" class="d-flex">
        <el-button @click="sendVerificationCode" class="w-100" :disabled="countdownCounter !== 0">
          Resend
          <span v-if="countdownCounter >= 1">{{ countdownCounter }}</span>
        </el-button>
        <el-button @click="handleVerify" class="w-100" :disabled="!isValidVerifyCode" type="success" native-type="submit">
          Verify
        </el-button>
      </div>
    </el-dialog> -->
  </div>
</template>
<script>
import { sendVerificationCode, verifyCode } from '@/api/login'
import { getHash } from '@/api/user'
import { clearAll } from '@/utils/auth'
import SelectClientModal from '@/components/Widgets/SelectClientModal'

export default {
  components: {
    SelectClientModal,
  },
  data() {
    return {
      showSelectClientDialog: false,
      clientsPermission: [],
      availableClients: [],
      countdownCounter: 0,
      codeLength: 6,
      isShowVerificationDialog: false,
      verification: {
        code: '',
        user_id: '',
        is_trust: false,
        hash: '',
      },
      authType: 'two_factor',
    }
  },
  methods: {
    handleShowClientDialog() {
      if (this.isAccessMultiClient) {
        this.showSelectClientDialog = true
      }
    },
    handleSelectClient(selectedClient) {
      const validClient = this.clients.find(client => client._id === selectedClient._id && client.is_active)
      if (validClient) {
        clearAll()
        this.$store.dispatch('setClient', selectedClient.code)
        this.$store.commit('SET_TOKEN', '')
        this.$notify({ type: 'info', message: `Processing to client "${selectedClient.name}"...`, customClass: 'jovy' })
        setTimeout(() => {
          //window.location.href = '/'
          this.$router.push({ name: 'Login' })
        }, 100)
      } else {
        this.$notify({ type: 'error', message: `The selected client "${selectedClient.name}" is not available to access for now`, customClass: 'jovy' })
      }
    },
    async currentUserInfomartion() {
      for (const client of this.clients) {
        const isActive = client.is_active || false
        this.clientsPermission.push({
          client: client._id,
          permission: isActive
        })
        if (isActive) {
          this.availableClients.push(client._id)
        }
      }
    },
    async sendVerificationCode() {
      this.isLoading = true
      const { success, code } = await sendVerificationCode({
        method: 'by_email',
        user_id: this.verification.user_id,
        hash: this.verification.hash,
        type: this.authType,
        un_trust: true,
      })
        .then(res => res.data)
        .catch(() => ({ success: false }))
      this.isLoading = false
      if (success) {
        if (code) {
          setTimeout(() => {
            prompt('Verification code: ', code)
          }, 1000)
        }
        this.handleCountdown()
      }
    },
    handleCountdown() {
      const countdown = async () => {
        await new Promise(resolve => {
          setTimeout(() => resolve(), 1000)
        })
        if (this.countdownCounter >= 1) {
          this.countdownCounter -= 1
          return countdown()
        }
        return false
      }
      this.countdownCounter = -1
      setTimeout(() => {
        this.countdownCounter = 60
        countdown()
      }, 1500)
    },
    handleChangeCode(code) {
      this.verification.code = code
    },
    async showVerificationDialog() {
      this.isLoading = true
      this.isShowVerificationDialog = true
      this.verification.user_id = this.$store.getters.user._id
      const { data: { hash, success } } = await getHash().catch(() => ({ success: false }))
      if (success) {
        this.verification.hash = hash
        await this.sendVerificationCode()
      }
      this.isLoading = false
    },
    async handleVerify() {
      this.isLoading = true
      const params = { ...this.verification, type: this.authType }
      const { success, token, user } = await verifyCode(params)
        .then(res => res.data)
        .catch(() => ({ success: false }))
      this.isLoading = false
      if (success) {
        this.$store.dispatch('setLoginData', { user, token })
        this.isShowVerificationDialog = false
        this.showSelectClientDialog = true
        this.$refs.code_input.clearCode()
      }
    },
  },
  computed: {
    isAccessMultiClient () {
      return this.currentClient && this.clients.length > 1
    },
    pageTitle() {
      return this.$store.getters.pageTitle
    },
    clientCode() {
      return this.$store.getters.client || ''
    },
    clients() {
      return this.$store.getters.clients || []
    },
    currentClient() {
      return this.clients.find(client => client.code === this.clientCode)
    },
    isValidVerifyCode() {
      return this.verification.code.length == this.codeLength
    },
  },
  mounted() {
    this.currentUserInfomartion()
  }
}
</script>
