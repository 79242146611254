<template>
  <div class="sidebar-items">
    <div class="mb-2 float-right">
      <span class="fs-12 text-gray-500 mr-2">Collapse Menu</span>
      <toggle-sidebar-button ref="toggle-sidebar" position="sidebar" name="sidebar-btn"></toggle-sidebar-button>
    </div>
    <jv-input v-model="keyword" @input="handleSearch" placeholder="Search Settings" size="medium" class="mw-100 w-100">
      <i class="bi bi-search" slot="prefix"></i>
    </jv-input>
    <collapse-sidebar :sidebar-items="allItems" :search="true" :searching="searching" class="py-2 sidebar-setting"></collapse-sidebar>
  </div>
</template>
<script>
import Items from './items'
import CollapseSidebar from '../../Widgets/CollapseSidebar.vue'
import ToggleSidebarButton from '@/components/Views/Settings/General/ToggleSidebarButton'

export default {
  data() {
    return {
      allItems: Items.map(item => { return { ...item } }),
      keyword: '',
    }
  },
  created() {
    this.allItems = this.allItems
      .filter(this.canShowItem)
      .map(item => {
        item.children = item.children.filter(this.canShowItem)
        return item
      })
  },
  methods: {
    handleSearch() {
      const keyword = this.keyword.toLowerCase()
      this.allItems.forEach((parent) => {
        let isHide = true
        parent.children.forEach((child) => {
          this.$set(child, 'isHide', true)
          if (child.name.toLowerCase().includes(keyword)) {
            isHide = false
            this.$set(child, 'isHide', false)
          }
        })
        this.$set(parent, 'isHide', isHide)
      })
    },
    canShowItem(item) {
      /* TODO - Check for feature toggle flag */
      if (!item.isHide) {
        return !item.clientType || item.clientType === 'all' || item.clientType === this.clientType || (item.clientType == 'full' && this.client.code == 'master')
      }
      return false
    },
  },
  computed: {
    searching() {
      return Boolean(this.keyword.trim())
    },
  },
  components: { CollapseSidebar, ToggleSidebarButton },
}
</script>
