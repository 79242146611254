import { chunk } from 'lodash/lodash.min'
import moment from 'moment'
export function transformRequestOptions(params) {
  let options = ''
  for (const key in params) {
    if (typeof params[key] !== 'object' && params[key]) {
      options += `${key}=${params[key]}&`
    } else if (typeof params[key] === 'object' && params[key] && params[key].length) {
      params[key].forEach(el => {
        options += `${key}=${el}&`
      })
    }
  }
  return options ? options.slice(0, -1) : options
}

export function removeEmptyKey (obj) {
  let newObj = {};
  for (const key in obj) {
    if (key === '$regex' && !obj[key]) {
      return {}
    }

    if (typeof obj[key] === 'object' && obj[key]) {
      let n = removeEmptyKey(obj[key])

      if (Object.keys(n).length) {
        newObj[key] = n
      }
    } else {
      // console.log(key)
      if (obj[key] !== undefined  && obj[key] !== '' && obj[key] !== null ) {
        if (/^\$(gt|lt)e?/.test(key)) {
          if (!isNaN(obj[key])) {
            newObj[key] = +obj[key]
          }
        } else {
          if (Array.isArray(obj)) {
            newObj = obj
          } else {
            newObj[key] = obj[key]
          }
        }
      }
    }
  }

  return newObj
}

export function timeConversion (millisec) {
  const seconds = (millisec / 1000).toFixed(1)
  const minutes = (millisec / (1000 * 60)).toFixed(1)
  const hours = (millisec / (1000 * 60 * 60)).toFixed(1)
  const days = (millisec / (1000 * 60 * 60 * 24)).toFixed(1)
  if (seconds < 60) {
      return seconds + " Sec"
  } else if (minutes < 60) {
      return minutes + " Min"
  } else if (hours < 24) {
      return hours + " Hour"
  } else {
      return days + " Day"
  }
}

export function mstoMinute (ms) {
  return ms / 60000;
}

export function minuteToMS (min) {
  return min * 60000;
}


// this map is from Quote schema
const STOCK_FIELDS = {
  salable_qty: 'salableQty',
  backorders_qty: 'backordersQty',
  is_backorders: 'isBackorders',
  is_in_stock: 'isInStock',
  stock_message: 'stockMessage',
  stock_qty: 'stockQty',
  backorders_message: 'backordersMessage',
  stock_status: 'stockStatus',
  stock_status_text: 'stockStatusText',
  max_cart_qty: 'maxCartQty'
}

function _normalizeProduct(productOrItem) {
  const product = {}
  Object.entries(STOCK_FIELDS).forEach(entry => {
    product[entry[1]] = productOrItem[entry[1]] || productOrItem[entry[0]]
  })

  if (typeof productOrItem.row_total === 'undefined') {
    product._isProduct = true
  }

  return product
}

export function getStockInfo(productOrItem, qtyToCheck = 1) {
  const product = _normalizeProduct(productOrItem)
  if (product._isProduct && +product.final_price <= 0) {
    return {
      status: 'out-of-stock',
      text: 'Invalid Price',
      isInStock: false,
    }
  }

  const info = {
    status: product.stockStatus,
    text: product.stockStatusText,
    isInStock: product.isInStock || false
  }

  if (product.salableQty > 0) {
    info.isInStock = true
    info.message = ''

    if (product.stockQty > 0) {
      info.status = 'in-stock'
      info.text = 'In Stock'

      if (product.stockQty > product.salableQty) {
        if (qtyToCheck > product.salableQty) {
          info.message = `Limit of ${product.salableQty}`
        }
      } else if (qtyToCheck > product.stockQty) {
        if (product.backordersQty > 0) {
          info.status = 'limited-stock'
          info.text = 'Limited Stock'

          let qtyToBackorder = qtyToCheck - product.stockQty
          const messageChunks = []
          if (qtyToBackorder > product.backordersQty) {
            if (product.salableQty < product.maxCartQty) {
              messageChunks.push(`Only ${product.salableQty} available,`)
            } else {
              messageChunks.push(`Limit of ${product.salableQty},`)
            }
            qtyToBackorder = product.backordersQty
          }

          info.qtyToBackorder = qtyToBackorder

          messageChunks.push(`<span class="in-stock">${product.stockQty} in stock,</span>`)
          messageChunks.push(`<span class="backordered text-break">${qtyToBackorder} backordered</span>`)
          info.message = messageChunks.join(' ')
        } else {
          if (product.stockQty >= product.maxCartQty) {
            info.message = `Limit of ${product.stockQty}`
          } else {
            info.message = `Only ${product.stockQty} in stock`
          }
        }
      }
    } else {
      info.status = 'backordered'
      info.text = `Backordered${product.backordersMessage ? ': ' + product.backordersMessage : ''}`

      const messageChunks = []
      if (qtyToCheck > product.backordersQty) {
        qtyToCheck = product.backordersQty
        if (product.backordersQty < product.maxCartQty) {
          messageChunks.push(`Only ${product.backordersQty} available,`)
        } else {
          messageChunks.push(`Limit of ${product.salableQty},`)
        }
      }
      messageChunks.push(`<span class="backordered">Backordered</span>`)
      info.message = messageChunks.join(' ')
    }
  } else {
    info.message = 'Out of Stock'
  }

  return info
}

export function formatHightlight(text, value) {
  return value.replace(text, '<strong class="color-text-primary">' +text+ '</strong>')
}
export function formatNumber(num, type = '', decimals = 4) {
  if(!num) num = 0
  if(typeof num === 'string') num = Number(num)
  if(num === Infinity) num = 0
  if(type === 'price') num = '$' + num.toFixed(decimals)
  else if(type === 'decimal')  num = num.toFixed(decimals)
  return num
}

export function exportCSVDataJson(filename, jsonData) {
  if(Array.isArray(jsonData) && jsonData.length) {
    let keys = Object.keys(jsonData[0]);
    let columnDelimiter = ',';
    let lineDelimiter = '\n';

    let csvColumnHeader = keys.join(columnDelimiter);
    let csvStr = csvColumnHeader + lineDelimiter;

    jsonData.forEach(item => {
      keys.forEach((key, index) => {
        if( (index > 0) && (index < keys.length) ) {
          csvStr += columnDelimiter;
        }
        csvStr += typeof item[key] === 'string' ? (item[key].search(',') >= 0 ? `"${item[key]}"` : item[key]) : item[key];
      });
      csvStr += lineDelimiter;
    });
    let data =  encodeURIComponent(csvStr);
    let dataUri = 'data:text/csv;charset=utf-8,'+ data;
    let exportFileDefaultName = `${filename}.csv`;
    let linkElement = document.createElement('a');
    linkElement.setAttribute('href', dataUri);
    linkElement.setAttribute('download', exportFileDefaultName);
    linkElement.click();
    return true
  }
}

export function exportCustomCSVDataJson(filename, jsonData) {
  if (Array.isArray(jsonData) && jsonData.length) {
    let keys = Object.keys(jsonData[0]);
    let columnDelimiter = ',';
    let lineDelimiter = '\n';

    let csvColumnHeader = keys.join(columnDelimiter);
    let csvStr = csvColumnHeader + lineDelimiter;

    jsonData.forEach(item => {
      keys.forEach((key, index) => {
        if ((index > 0) && (index < keys.length)) {
          csvStr += columnDelimiter;
        }
        if ((item[key] + '').includes(',')) {
          csvStr += "\"" + item[key] + "\"";
        } else {
          csvStr += item[key];
        }
      });
      csvStr += lineDelimiter;
    });
    let data = encodeURIComponent(csvStr);
    let dataUri = 'data:text/csv;charset=utf-8,' + data;
    let exportFileDefaultName = `${filename}.csv`;
    let linkElement = document.createElement('a');
    linkElement.setAttribute('href', dataUri);
    linkElement.setAttribute('download', exportFileDefaultName);
    linkElement.click();
    return true
  }
}

export function formatPrice(value, currency = {}) {
  value = parseFloat(value)
  if (isNaN(value)) {
    value = 0
  } else {
    // add compensation to fix the rouding issue
    value += 0.000000000000001
  }

  currency = Object.assign({}, this.currency, currency)
  let price = Math.abs(value).toFixed(currency.fractionDigits)
  price = (Number(price)).toLocaleString("en-US", {style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2});
  if (currency.position < 0) {
    price = currency.symbol + price
  } else if (currency.position > 0) {
    price += currency.symbol
  }
  if (+value.toFixed(currency.fractionDigits) < 0) {
    price = '-' + price
  }
  return price
}

/* replace all line-break to <br/> */
export function nl2br(text) {
  return text.replace(/\r?\n/g, '<br/>')
}

export function getChunkedItems(items, perChunk = 2) {
  return chunk(items, perChunk)
}

export function formatDate(date, format = 'MM/DD/YYYY') {
  return moment(date).utc().format(format)
}

export function cutText(htmlText, split = 80) {
  const text = htmlText.replace(/(<([^>]+)>)/gi, "")
  const splitText = text.split(' ')
  if (splitText.length < split) return htmlText
  return text.split(' ').slice(0, split).join(' ') + ' ...'
}

export function getAdminUrl (url) {
  let completeUrl = new URL(url);
  return completeUrl.origin;
}

export function getParsedCallbackUrl (cbkUrl) {
  if (cbkUrl.search('/#/') >= 0) {
    const url = new URL(cbkUrl);
    return url.protocol+'//'+ encodeURIComponent(`${url.host}/${url.hash}`)
   }
   return cbkUrl
}

export function setTimeoutPromise(timeoutMs) {
  return new Promise((resolve) => {
    setTimeout(resolve, timeoutMs);
  });
}

export function formatPhoneNumber(phoneNumber) {
  if (!phoneNumber) {
    return '';
  }
  let cleaned = phoneNumber.replace(/^\+?1|\|1|[a-zA-Z]\b|\D/g, ''); // remove +1 (USA country code), TODO: update for multi country
  if (cleaned.slice(0, 1) == '1') {
    return formatPhoneNumber(cleaned);
  }
  cleaned = cleaned.slice(0, 10);
  if (cleaned.length <= 4) {
    return cleaned;
  }
  const part1 = cleaned.length > 4 ? `(${cleaned.substring(0, 3)}) ${cleaned.substring(3, 6)}` : '';
  const part2 = cleaned.length > 6 ? `-${cleaned.substring(6, 10)}` : '';
  return `${part1}${part2}`;
}

/**
 * Checks if two arrays of objects are equal, ignoring the order of elements.
 *
 * @param {array} array1 - The first array of objects.
 * @param {array} array2 - The second array of objects.
 * @param {array} compareFields - An array of field names to be compared.
 * @returns {boolean} True if the arrays are equal, false otherwise.
 */
export function compareObjectArrays(array1, array2, compareFields) {
  // Check if arrays have the same length
  if (array1.length !== array2.length) {
    return false;
  }
  // Convert arrays to objects for efficient comparison
  const obj1 = Object.fromEntries(array1.map(obj => Object.assign({}, ...compareFields.map(key => ({ [key]: obj[key] }))))
    .map((obj) => [JSON.stringify(obj), obj]))
  const obj2 = Object.fromEntries(array2.map(obj => Object.assign({}, ...compareFields.map(key => ({ [key]: obj[key] }))))
    .map((obj) => [JSON.stringify(obj), obj]))
  // Check if each element in one array exists in the other and has the same values for the specified fields
  for (const key in obj1) {
    if (!obj2.hasOwnProperty(key) || !compareFields.every((field) => obj1[key][field] === obj2[key][field])) {
      return false;
    }
  }
  return true;
}