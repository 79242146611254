import { render, staticRenderFns } from "./RootLayout.vue?vue&type=template&id=51d7ead3&scoped=true"
import script from "./RootLayout.vue?vue&type=script&lang=js"
export * from "./RootLayout.vue?vue&type=script&lang=js"
import style0 from "./RootLayout.vue?vue&type=style&index=0&id=51d7ead3&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "51d7ead3",
  null
  
)

export default component.exports