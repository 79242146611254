<template>
  <div class="d-flex p-lg-2 p-0 wrapper" v-if="isAuthenticated">
    <!-- <side-bar-component @sidebarExpand="isSidebarExpand = $event" :is-header="true" v-if="!$isMobile"/> -->
    <flyout-sidebar class="d-none d-lg-block"></flyout-sidebar>
    <section class="content" ref="content" id="wrapper-content" :class="isSidebarExpand ? 'minus-180' : ''" v-if="isLoaded" v-loading="loadingStatus > 0">
      <!-- <header-component/> -->
      <root-content :key="renderKey"/>
      <confirm-modal/>
    </section>
  </div>
</template>

<script>
import { firstRequest } from '@/api/helper'
import { getOne } from '../../utils/app'
import { getToken } from '@/utils/auth'
import RootContent from './RootContent.vue'
import FlyoutSidebar from './FlyoutSidebar.vue'
// import SideBarComponent from './SideBar'

export default {
  data () {
    return {
      isLoaded: false,
      isSidebarExpand: false,
      renderKey: Date.now()
    }
  },
  components: {
    // SideBarComponent,
    RootContent,
    FlyoutSidebar
},
  async mounted () {
    await this.firstRequest()
    /* Check admin status */
    if (this.client) {
      if (this.client.isEssential && this.client.admin_status === false) {
        return this.$router.replace({ name: 'registration', params: { issue: 'inactive' } })
      }
    }

    const theme = getOne('layout', 'THEME') || this.THEME
    this.theme = theme === 'v2'
    this.$store.dispatch('setNewTheme', this.theme)
  },
  computed: {
    isAuthenticated() {
      return Boolean(this.$store.getters.user && this.$store.getters.token)
    },
    loadingStatus() {
      return this.$store.getters.loadingStatus
    },
  },
  methods: {
    handleSidebar(event){
      this.isSidebarExpand = event
    },
    async firstRequest() {
      await firstRequest()
        .then(({ data, headers }) => {
          if (data.success) {
            this.$store.dispatch('setFirstData', data)
            if (data.clients) {
              this.$store.commit('SET_CLIENTS', data.clients)
            }
            if (data.client) {
              this.$store.commit('client/SET_CLIENT', data.client)
              localStorage.setItem('isEssential', data.client.isEssential)
            }
            if (data.lock_period) {
              // Commented for testing purpose:
              // this.$store.commit('SET_LOCK', data.lock_period)
            }
          }
          if(headers['system-name']) {
            this.$store.commit('SET_SYSTEM_NAME', headers['system-name'])
          }
        })
        .catch(() => {})
        .finally(() => { this.isLoaded = true })
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  height: 100vh;
  padding-right: 0 !important;
}
.content {
  flex-grow: 1;
  max-width: 100%;
  // max-height: 100%;
  // overflow: auto;
  @media (min-width: $lg) {
    padding: 0 8px 0 calc(#{$min-sidebar-size} + 8px);
    // width: calc(100% - #{$min-sidebar-size});
  }
}
</style>
